@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {

    body {
        @apply text-gray-700 bg-light-bg dark:bg-dark-page dark:text-text-dark relative
    }


    /* notification */
    .notification {
        @apply fixed duration-150 inset-x-0 z-50 mx-auto items-center rounded-md bg-white text-[#292B2D] max-w-fit w-full translate-y-[30px] animate-[fade-in] ease-linear shadow-md
    }

    .notification__description {
        @apply flex gap-2 items-center grow-[2]
    }

    .notification__icon {
        @apply w-5 h-5 mr-2
    }

    .notification__body {
        @apply flex flex-row px-4 py-3
    }

    .notification__button {
        @apply flex items-center justify-center cursor-pointer p-0 bg-white whitespace-nowrap ml-10 grow text-red-700 hover:shadow-md px-3 py-1.5 text-sm rounded-md
    }


    /* Form styles */
    .form-label {
        @apply block text-sm font-medium leading-6 text-gray-700 dark:text-white
    }
    
    .form-input {
        @apply mt-2 appearance-none text-slate-700 dark:text-slate-200 bg-white dark:bg-hover-bg placeholder:text-slate-400 dark:placeholder:text-slate-200 border border-gray-200 dark:border-hover-bg rounded-md block w-full px-3 h-10 shadow-sm sm:text-sm 
    }

    .form-textarea {
        @apply p-2 mt-1 block w-full rounded-md text-slate-700 dark:text-text-dark bg-white dark:bg-hover-bg border border-gray-200 dark:border-hover-bg placeholder:text-slate-400 dark:placeholder:text-text-dark shadow-sm sm:text-sm
    }

    .form-button {
        @apply inline-flex justify-center rounded-md text-sm font-semibold py-2.5 px-4 bg-light-blue text-white hover:bg-dark-blue w-full
    }

    .application-button {
        @apply inline-flex justify-center rounded-md text-sm font-semibold py-2.5 px-4 bg-gold-700 text-white hover:bg-gold-500 w-full
    }

    .transparent-button {
        @apply inline-flex justify-center rounded-md text-sm font-semibold py-2.5 px-4 bg-transparent text-gold-700 hover:bg-gold-500 hover:text-white border border-gold-500
    }

    .form-button-default {
        @apply inline-flex justify-center rounded-md text-sm font-semibold py-2.5 px-4 bg-slate-100 text-slate-700 hover:bg-slate-300 w-full
    }
    /* Form styles */


    /* Password Strength */
    .password-strength {
        @apply w-full h-auto my-1 gap-1
    }

    .password-weak {
        @apply bg-[#e74c3c] !important;
    }
    
    .password-medium {
        @apply bg-[#e67e22] !important;
    }
    
    .password-strong {
        @apply bg-[#2ecc71] !important;
    }
    
    .password-transparent {
        @apply bg-transparent !important;
    }
    
    .password-line {
        @apply h-1.5 w-[33%] bg-transparent rounded-md transition delay-75 duration-100
    }
    /* Password Strength */


    /* Mobile Menu Styles */
    .mobile-menu {
        @apply bg-white dark:bg-dark-bg border-r border-gray-100 dark:border-light-border h-screen opacity-100 flex flex-col p-3 left-0 fixed shadow-md w-80 z-10 animate-openMenu
    }

    .hide-mobile-menu {
        @apply animate-closeMenu
    }
    /* Mobile Menu Styles */

    /* General styles */
    .display_card {
        @apply p-4 md:p-8 lg:p-8 rounded-md shadow-sm mx-2 my-2 md:mx-4 md:my-6 lg:mx-4 lg:my-6 bg-white dark:bg-dark-bg
    }

    .mini_card {
        @apply border w-full h-auto p-6 rounded-lg shadow-sm flex flex-col dark:border-white/[.2]
    }

    .loan_card {
        @apply overflow-hidden rounded-md shadow-sm mx-2 my-2 md:my-2 md:mx-2 bg-white dark:bg-dark-bg
    }
    /* General styles */

}